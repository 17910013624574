@import "@scss/base";

.mini-basket {
  $module: ".mini-basket";

  @include menuOverlay;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include mq($from: vp5) {
    padding-top: rem(60);
    display: block;
  }

  &__headline {
    h2 {
      @include fontStyle(heading-2);
    }

    &--no-products {
      align-self: center;
      text-align: center;
      height: 50vh;
      p {
        padding-top: rem(20);
      }
    }
  }
  &__notice {
    margin-top: rem(20);
    color: var(--c-s-error2);
  }
  &__products {
    margin-top: rem(26);
    border-top: rem(1) solid var(--c-b-grey3);
    .mini-basket-product {
      border-bottom: rem(1) solid var(--c-b-grey3);
    }
  }

  &__addon {
    border-bottom: rem(1) solid var(--c-b-grey3);
  }
  &__calculation {
    padding-top: rem(20);
  }
  &__cta-row {
    padding-top: rem(35);
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: rem(30);
    .voucher {
      margin-bottom: rem(35);
    }
    @include mq($from: vp5) {
      padding: rem(35) 0;
      justify-content: space-between;
      flex-direction: row;
    }
    .icon-link {
      @include mq($until: vp5) {
        margin-bottom: rem(10);
      }
    }
    .action-button {
      display: flex;
      width: 100%;
      margin-left: auto;
      @include mq($from: vp5) {
        width: auto;
        display: inline-flex;
      }
    }
  }

  &__footer {
    z-index: 1;
    margin-top: rem(30);

    @include mq($from: vp5) {
      position: fixed;
      left: rem(20);
      right: rem(20);
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__usp {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--c-b-white);
    @include mq($from: vp5) {
      justify-content: space-between;
      flex-direction: row;
      padding: rem(20) rem(60);
    }
    @include mq($from: vp7) {
      padding: rem(30) rem(60);
    }

    &-item {
      text-transform: uppercase;
      padding-top: rem(14);
      text-align: center;
      font-size: rem(14);
      @include mq($from: vp5) {
        padding-top: 0;
        flex: 1 0 30%;

        max-width: 30%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-size: rem(12);
      }
      @include mq($from: vp9) {
        font-size: rem(16);
      }
    }
    &-item-separator {
      display: none;
      @include mq($from: vp5) {
        display: flex;
      }
    }
  }
}

.icon-link {
  font-size: rem(14);
  .svg-icon {
    margin-right: rem(10);
  }
}
.voucher {
  position: relative;
  width: 100%;
  display: block;
  @include mq($until: vp5) {
    margin-bottom: rem(20);
  }

  &__input {
    display: inline-flex;
    align-content: center;
    justify-content: space-between;
    background-color: var(--c-t-beige);

    input {
      border: none;

      flex: 1;

      height: rem(40);
      width: 100%;
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      @include fontStyle(paragraph-1);

      padding: 0 rem(20);
      @include mq($from: vp4) {
        flex: 0 1 40%;
        max-width: 40%;
        height: rem(50);
        padding: 0 rem(30);
      }
      @include mq($from: vp6) {
        height: rem(62);
        padding: 0 rem(40);
      }
    }
    button {
      padding-right: rem(20);
      text-decoration: underline;
    }
  }

  p {
    top: 100%;
    left: 0;
    margin-top: rem(5);
    @include fontStyle(paragraph-2);
    color: var(--c-s-error1);
    //@include mq($from: vp5) {
    position: absolute;
    //}
  }
}
