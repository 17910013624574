@import "@scss/base";

.product-tiles-slider {
  $module: ".product-tiles-slider";
  @include verticalSpacing;

  &__slide {
    width: 70%;
    border-bottom: 0.1px solid var(--c-b-grey3);
    &:not(:last-child) {
      @include gridGapRight();
    }
    @include mq($from: vp4) {
      width: 37.1%;
    }
    @include mq($from: vp5) {
      width: 21.6%;
    }
    .product-tile {
      margin-bottom: rem(20);

      &__link {
        min-height: rem(11);
      }     
    }
  }
  .product-tile__image {
    height: 0;
    position: relative;
    padding-bottom: 100%;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
  }

  .swiper-container {
    padding-bottom: rem(30);
    box-sizing: border-box;
    padding-left: 2vw;
    @include mq($from: vp3) {
      padding-left: 13vw;
    }
    @include mq($from: vp6) {
      padding-left: 12.5vw;
    }
    @include mq($from: vp8) {
      padding-left: 14.5vw;
    }
    @include mq($from: vp9) {
      padding-left: 16vw;
    }
  }

  // HEADER with nav on mobile
  .carousel__header {
    height: auto;
    margin-bottom: 0;
    @include mq($from: vp4) {
      margin-bottom: rem(40);
    }
    .carousel__nav {
      display: block;
      .carousel__next {
        padding: rem(10) 0 rem(10) rem(10); //remove side padding to fit to border
        @include mq($from: vp4) {
          padding: rem(5) rem(10);
        }
      }
    }
  }
}
