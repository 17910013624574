@import "@scss/base";

.product-tile {
  $module: ".product-tile";
  position: relative;
  &:hover {
    #{$module}__image {
      opacity: 0.8;
    }
    #{$module}__link {
      &:before {
        right: 0;
      }
    }
  }
  &__image {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    display: block;
    transition: opacity var(--default-transition);
    width: 100%;
    background-color: var(--c-t-beige);

    .image {
      box-sizing: border-box;
      padding: 15% 6%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;
      &--full-horizontal {
        padding: 10% 2%;
      }
      &--full-vertical {
        padding: 2%% 4%;
      }
      &--scale-small {
        padding: 23% 18%;
      }
      &--scale-medium {
        padding: 15% 12%;
      }
      &--scale-large {
        padding: 10% 6%;
      }
      .image__inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      &.no-image {
        padding: 10% 6%;
      }
    }
  }
  .product-tile-link-container{
    display: flex;
    justify-content: space-between;
  }

  &__text {
    padding-top: rem(12);

    .product-price {
      font-size: 10px;
    }

    sup {
      font-size: 75%;
      position: relative;
      top: rem(-5);
    }
    * {
      min-height: 1em;
    }

    h3 {
      //@include fontStyle(heading-4);
      font-size: 13px;
      padding-bottom: rem(6);
      min-height: 1em;

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      //@include fontStyle(paragraph-2);
      // height: calc(1.57143 * 2em); //to get two lines at least
      font-size: 10px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h5 {
      @include fontStyle(paragraph-2);

      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      min-height: rem(22);
      @include mq($from: vp8) {
        min-height: rem(25);
      }
      @include mq($from: vp9) {
        min-height: rem(30);
      }
    }
    h6 {
      @include fontStyle(paragraph-2);
      color: var(--c-b-grey2);
      text-decoration: line-through;
      min-height: rem(22);
      @include mq($from: vp8) {
        min-height: rem(25);
      }
      @include mq($from: vp9) {
        min-height: rem(30);
      }
    }
  }

  &__link {
    //@include fontStyle(paragraph-2);
    font-size: 10px;
    font-weight: $weight-medium;
    display: inline-flex;
    align-items: center;
    padding-top: rem(17);

    @include animated-underline(true);
    .icon {
      margin-right: rem(10);
    }
  }

  &__tag {
    text-transform: uppercase;
    //position: absolute;
    //top: rem(5);
    //right: rem(5);
    margin-top: rem(5);
    margin-right: rem(5);
    display: inline-flex;
    padding: rem(7);
    font-size: rem(9);

    @include mq($from: vp3) {
      padding: rem(9);
    }

    @include mq($from: vp5) {
      font-size: rem(8);
      padding: rem(3);
    }

    @include mq($from: vp7) {
      font-size: rem(9);
      padding: rem(7);
    }

    @include mq($from: vp8) {
      font-size: rem(10);
      padding: rem(10);
    }

    line-height: 1.2;
    background-color: var(--c-b-white);

    &__wrapper{
      display: flex;
      justify-content: flex-end;
    }
  }

  .delivery-costs-notice {
    display: block;
    font-size: rem(14);
    line-height: 1;
    text-decoration: underline;
    margin-top: rem(5);
  }
}
