@import "@scss/base";

.mini-basket-addon {
  $module: ".mini-basket-addon";

  display: grid;
  padding: 0 0 rem(20) 0;

  @include mq($from: vp3) {
    padding: 0;
  }

  border-bottom: rem(1) solid var(--c-b-grey3);
  align-items: center;

  grid-template-columns: 1fr 1fr;
  @include mq($from: vp3) {
    grid-template-columns: rem(100) auto;
  }
  
  grid-template-areas: "image text"
  "button button";

  grid-gap: 0 rem($grid-gap-small);

  @include mq($from: vp3) {
    grid-template-columns: rem(110) 1fr rem(150);
    grid-template-areas: "image text button";
  }

  &__image {
    grid-area: image;
    display: block;
    background-color: var(--c-t-beige);
    position: relative;
    height: 0;
    padding-bottom: 100%;

    .addon-product-image {    
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      max-height: inherit;
      max-width: inherit;
      box-sizing: border-box;
      
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }  

      &--full-horizontal {
        padding: 10% 2%;
      }
      &--full-vertical {
        padding: 2%% 4%;
      }
      &--scale-small {
        padding: 23% 18%;
      }
      &--scale-medium {
        padding: 15% 12%;
      }
      &--scale-large {
        padding: 10% 6%;
      }
    }

  }

  &__text {
    grid-area: text;
    align-self: center;

    h4 {
      @include fontStyle(headline-5);
    }

    p {
      @include fontStyle(paragraph-1);
    }
  }

  &__button {
    grid-area: button;
    align-self: center;
    justify-content: center;
    display: inline-flex;

    span+span {
      margin-left: rem(20);
    }

    @include mq($from: vp5) {
      justify-content: flex-end;
    }
  }
}