@import "@scss/base";

.pdp-configurator {
  $module: ".pdp-configurator";

  --configurator-height: auto;
  --description-height: auto;
  --param-count: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  // ensures no scroll, but hides buttons!
  height: var(--configurator-height);

  @include mq($from: vp5) {
    --configurator-height: calc(100 * var(--vh) - var(--site-header-height) - var(--mode-switch-height) - rem(30));
  }

  .professional-add-to-cart {
    margin-left: rem(10);
  }

  .action-button--primary {

    .text {
      font-size: rem(12);
      text-transform: none;
    }
  }

  .action-button--secondary {
    .text {
      font-size: rem(12);
      text-transform: none;
    }
  }

  &.is-configuring {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: rem(20);
    background-color: var(--c-b-white);
    z-index: 1;
    //height: calc(100 * var(--vh) - rem(40));
    padding-top: var(--mode-switch-height);
    //height: calc(80vh - rem(40));

    @include mq($from: vp4) {
      padding: var(--mode-switch-height);
    }

    @include mq($from: vp5) {
      background-color: transparent;
      padding: 0;
      position: static;
      height: calc(100 * var(--vh) - rem(30) - var(--site-header-height));
    }

    @include mq($from: vp7) {
      height: calc(100 * var(--vh) - rem(60) - var(--site-header-height));
    }
  }


  &__back {
    transform: rotate(180deg);
  }

  &__debug {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #e9f;
    padding: rem(20);
  }

  &__notify-me-message {
    position: absolute;
    top: calc(100% + rem(10));
    left: 0;
    right: 0;
    text-align: center;
    @include fontStyle(paragraph-3);
  }

  &__header {
    display: flex;
    flex-direction: column;

    @include mq($from: vp5) {
      border-top: rem(1) solid var(--c-s-border);
      padding-top: rem(10);
    }

    @include mq($from: vp6) {
      padding-top: rem(20);
    }

    &__tag {
      top: rem(5);
      text-transform: uppercase;
      right: rem(5);
      display: inline-flex;
      padding: rem(7) rem(10);
      font-size: rem(10);
      line-height: 1.2;
      background-color: var(--c-b-white);
      border: rem(1) solid var(--c-b-black);
      margin-right: rem(10);
    }

    &__manchet-text {
      margin-bottom: rem(10);
    }

    &__text {
      h1 {
        @include fontStyle(heading-2);
      }

      p {
        position: relative;
        @include fontStyle(paragraph-2);
        margin-bottom: rem(10);

        // &:after {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   bottom: 0;
        //   right: 0;
        //   left: 0;
        //   height: rem(22);
        //   background: linear-gradient(transparent 20%, #ffffff);
        // }

        @include mq($from: vp5) {
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        @include mq($from: vp6) {
          margin-bottom: rem(13);
        }

        @include mq($from: vp8) {
          -webkit-line-clamp: 10;
        }

        @include mq($from: vp9) {
          -webkit-line-clamp: 13;
        }
      }

      .basic-link {
        display: none;

        @include mq($from: vp5) {
          display: inline-flex;
          align-items: center;
        }

        .icon {
          transform-origin: center;
          transform: rotateZ(90deg);
        }
      }
    }

    &__button {
      display: inline-flex;
      align-items: center;

      span+span {
        margin-left: rem(10);
      }
    }

    &__discount-price-wrapper {
      margin-left: auto;
      padding-right: rem(10);

      @include mq($until: vp5) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-bottom: rem(10)
      }

      .pdp-discount-price {
        @include mq($until: vp3) {
          display: none;
        }
        span {
          @include fontStyle(pdp-discount-price);
          line-height: 1 !important;
          text-decoration: line-through;
          opacity: .5;
        }
      }
    }

    &__price-wrapper {
      .pdp-discount-price {
        @include mq($from: vp3) {
          display: none;
        }
        span {
          @include fontStyle(pdp-discount-price);
          line-height: 1 !important;
          text-decoration: line-through;
          opacity: .5;
        }
      }
      @include mq($until: vp5) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }

      .klarna-notification {
        margin-top: rem(15);

        span {
          @include fontStyle(paragraph-3);
        }
      }
    }

    &__price {
      display: flex;
      justify-content: space-between;
      border-top: rem(1) solid var(--c-b-grey3);
      padding: rem(15) 0;
      text-align: right;

      .quantity-selector {
        display: flex;
        align-items: end;
      }

      .quantity-selector-container {
        .quantity-selector {
          margin-top: rem(10);
        }
      }
    }

    &__klarna {
      padding: 0 0 rem(15) 0;
    }

    .pdp-price {
      margin-left: auto;

      align-items: center;

      span {
        @include fontStyle(heading-4);
        line-height: 1 !important;
      }

      small {
        @include fontStyle(paragraph-3);
        margin-right: rem(10);
      }

      .delivery-costs-notice {
        display: block;
        font-size: rem(14);
        line-height: 1;
        text-decoration: underline;
        margin-bottom: rem(3);
      }

      em {
        display: block;
        font-size: rem(10);
        line-height: 1;
        color: var(--c-b-grey2);
      }

      transition: opacity var(--default-transition);
      opacity: 0;

      &.has-resolved {
        opacity: 1;
      }
    }

    &__cta {
      @include mq($from: vp4) {
        display: flex;
      }

      .action-button+.action-button {
        margin-top: rem(10);

        @include mq($from: vp4) {
          margin-top: 0;
        }
      }

      .loader-dots {
        flex: 1;
      }

      .action-button {
        display: flex;
        width: 100%;
        align-self: flex-end;

        &--primary {
          &.is-disabled {
            @include shimmerGreen;
            opacity: 0.9;
            color: currentColor;
          }

          .text,
          .icon {
            position: relative;
            z-index: 2;
          }
        }

        @include mq($from: vp4) {
          height: 61px;
          width: auto;
          flex: 0 0 48%;
          padding-left: rem(20);
          padding-right: rem(20);
        }

        position: relative;
      }

      @include mq($from: vp4) {
        display: flex;
        justify-content: space-between;
      }

      .pdp-not-in-commercemarket-text {
        max-width: 50%;
        padding-left: 10px;
        font-size: x-small;

        strong {
          font-weight: bold;
        }

        @media screen and (max-width: 500px) {
          max-width: 100%;
          padding-top: 10px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
  }

  &__slides {
    display: flex;
    transition: transform var(--default-transition);
    height: 100%;
  }

  &__slide {
    min-width: 100%;
  }

  &__footer {
    position: relative;
    flex: 0 0 rem(62);
    display: flex;
    justify-content: space-between;

    @include mq($until: vp5) {
      padding-top: rem(15);
    }

    @include mq($from: vp5) {
      padding-top: rem(30);
    }

    margin-top: auto;
    border-top: rem(1) solid var(--c-b-grey3);

    p {
      position: absolute;
      top: rem(7);
      left: 0;
      right: 0;
      font-size: rem(11);
      text-align: center;
    }

    &__cta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;
      height: 61px;
    }

    .action-button {
      margin-left: rem(10);
    }

    .action-button--icon-link {
      margin-left: 0;
      margin-right: auto;
    }
  }

  &--2-params {
    --param-count: 2;
  }

  &--3-params {
    --param-count: 3;
  }

  &--4-params {
    --param-count: 4;
  }

  &--5-params {
    --param-count: 5;
  }

  &--6-params {
    --param-count: 6;
  }

  &--7-params {
    --param-count: 7;
  }
}

$pdp-mq-offset: 500;
$pdp-option-height: 70;

@media screen and (max-height: 750px) and (min-width: 1024px) {
  .pdp-configurator:not(.is-configuring) {
    height: auto !important;
  }
}

@for $i from 1 through 6 {
  @media screen and (max-height: #{$pdp-mq-offset + $i * $pdp-option-height}px) and (min-width: 1024px) {
    .pdp-configurator--#{$i}-params .pdp-configurator__header__text p {
      display: none;
    }
  }

  @media screen and (max-height: #{$pdp-mq-offset + $i * $pdp-option-height + 100}px) and (min-width: 1024px) {
    .pdp-configurator--#{$i}-params .pdp-configurator__header__text p {
      -webkit-line-clamp: 3;
    }
  }

  @media screen and (max-height: #{$pdp-mq-offset + $i * $pdp-option-height + 200}px) and (min-width: 1920px) {
    .pdp-configurator--#{$i}-params .pdp-configurator__header__text p {
      -webkit-line-clamp: 3;
    }

    .pdp-configurator:not(.is-configuring) {
      height: auto !important;
    }
  }
}